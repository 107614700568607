<template>
  <div class="view cheat-sheet">
    <navbar />
    <main class="flex-container">
      <div class="content">
        <header>
          <h1>Cheat Sheet</h1>
          <p>A quick-reference guide to conlang definition syntax.</p>
        </header>
        <section id="boxes">
          <article id="languages">
            <h3>Languages</h3>
            <p>Define a root language:</p>
            <code><pre>
              <div>+ my.language</div>
            </pre></code>
            <p>Identifiers may contain only A-Z, a-z, and 0-9, separated by dots (<code>.</code>).</p>
            <p>The language's name is automatically inferred from its identifier.</p>
            <p><code>my.language</code> → "My Language".</p>
            <p>Specify a custom name:</p>
            <code><pre>
              <div>+ pie : Proto-Indo-European</div>
            </pre></code>
            <h4>Descendants</h4>
            <p>Use <code>&lt;</code> to specify a parent language:</p>
            <code><pre>
              <div>+ pam : Proto-Auzger-Morlan</div>
              <div></div>
              <div>+ auzgarne &lt; pam</div>
              <div>+ old.morlanii &lt; pam</div>
              <div></div>
              <div>+ morlanii &lt; old.morlanii</div>
            </pre></code>
            <p>Results in the following family tree:</p>
            <img class="center" src="@/assets/images/cheat-sheet-family-tree.png" alt="A family tree showing the defined languages and their relationships.">
          </article>
          <article id="milestones">
            <h3>Milestones</h3>
            <p>Milestones are how you specify points on the timeline to simulate change over time.</p>
            <code><pre>
              <div>@ 100</div>
              <div>// Definitions here</div>
            </pre></code>
            <h4>Ranges</h4>
            <p>Words, rules, and languages defined after a milestone start at that point on the timeline.</p>
            <p>Rules that are only active for certain period, or words that fall out of use, can be accounted for with ranges:</p>
            <code><pre>
              <div>@ 1800-2000</div>
            </pre></code>
            <p>Words defined in the range will be removed from the lexicon after the end time, and rules won't affect words that enter the language after that time.</p>
            <h4>Revisiting Languages</h4>
            <p>Specifying a language at a milestone indicates that the following definitions belong to that language, even if it wasn't the most recently defined language:</p>
            <code><pre>
              <div>@ 0</div>
              <div>+ pam : Proto-Auzger-Morlan</div>
              <div></div>
              <div>@500</div>
              <div>+ auzgarne &lt; pam</div>
              <div>+ morlanii &lt; pam</div>
              <div></div>
              <div>@10, pam</div>
              <div>- know : [gek] : v. to know</div>
            </pre></code>
          </article>
          <article id="words">
            <h3>Words</h3>
            <p>Add a word to the lexicon:</p>
            <code><pre>
              <div>- speech : ['to.ki]</div>
            </pre></code>
            <p>Specify a definition:</p>
            <code><pre>
              <div>- good : ['bo.na] : adj. good, nice</div>
            </pre></code>
            <h4>Gloss</h4>
            <p>The first element, the gloss, is used to reference the word in code. It should include only A-Z, a-z, and 0-9, separated by dots (<code>.</code>).</p>
            <h4>Transcription</h4>
            <p>The second element, the transcription, specifies how the word is pronounced. It uses IPA characters.</p>
            <ul>
              <li>Use the full stop (<code>.</code>) to define syllable boundaries.</li>
              <li>
                Use apostrophes and commas to notate syllable stress.
                <ul>
                  <li><code>'</code> = primary stress</li>
                  <li><code>,</code> = secondary stress</li>
                  <li><code>''</code> = extra stress / overstress</li>
                </ul>
              </li>
            </ul>
            <br />
            <p class="hint">NOTE: some symbols and diacritics aren't available in the early access version, sorry!</p>
          </article>
          <article id="rules">
            <h3>Phonological Rules</h3>
            <p>Rules define how words change in pronunciation over time.</p>
            <h4>Unconditioned Changes</h4>
            <p>Unconditioned changes occur in all environments:</p>
            <code><pre>
              <div>$ [ɢ] > [g]</div>
            </pre></code>
            <h4>Conditioned Changes</h4>
            <p>Specify a condition to only apply the rule in certain environments:</p>
            <code><pre>
              <div>// g > ɣ, only before /i/</div>
              <div>$ [g] > [ɣ] / _i</div>
            </pre></code>
            <p>Check for word boundaries in a condition with <code>#</code>:</p>
            <code><pre>
              <div>// h > ʔ, word-initially</div>
              <div>$ [h] > [ʔ] / #_</div>
            </pre></code>
            <p>When checking for neighbouring sounds, syllable boundaries are ignored by default. Check for the presence of syllable boundaries with <code>.</code>:</p>
            <code><pre>
              <div>$ [u] > [w] / V._V</div>
            </pre></code>
            <h4>Feature-based Changes</h4>
            <p>Select any sound that has a certain feature, or group of features:</p>
            <p>Apply a change only between sounds that are vowels:</p>
            <code><pre>
              <div>$ [d] > [ð] / V_V</div>
            </pre></code>
            <p>Apply a change to all alveolar stop consonants:</p>
            <code><pre>
              <div>$ [C+alveolar+stop] > [ɾ] / V_V</div>
            </pre></code>
            <p>Only apply the change after consonants that aren't bilabial:</p>
            <code><pre>
              <div>$ [ɔ] > [ʌ] / [C-bilabial]_</div>
            </pre></code>
            <h4>Insertion &amp; Deletion</h4>
            <p>Insert a sound into a word:</p>
            <code><pre>
              <div>$ [] > [ʔ] / V._V</div>
            </pre></code>
            <p>Delete a sound from a word:</p>
            <code><pre>
              <div>$ [s] > [] / #_C</div>
            </pre></code>
          </article>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'

export default {
  name: 'CheatSheet',
  components: { Navbar },
}
</script>

<style lang="scss">
.cheat-sheet {
  .content {
    width: 100%;
    padding-top: 0;

    h1 {
      font-size: 32px;
      margin: 0;
    }

    #boxes {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 1rem;
      width: calc(100vw - 2rem);
      max-width: 100%;
      flex-wrap: wrap;
      overflow: hidden;

      article {
        background: var(--c-bg-shade);
        width: clamp(23%, 40ch, 100%);
        max-width: 100%;
        padding: 1rem;
        border-radius: 5px;
        margin: 0;

        p > code,
        li > code {
          background: var(--c-bg);
        }

        img.center {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}
</style>
